<template>
  <BaseTable
    ref="table"
    title="Pratiche"
    :headers="headers"
    :actions="actions"
    :elevation="0"
    module="tickets"
  >
    <template v-slot:item.external_id="{ item }">
      <v-chip label>{{ item.external_id }}</v-chip>
    </template>
    <template v-slot:item.activity="{ item }">
      {{ item.activity.macro }}
      <v-icon small>mdi-chevron-right</v-icon>
      {{ item.activity.category }}
      <v-icon small>mdi-chevron-right</v-icon>
      {{ item.activity.subcategory }}
    </template>

    <template v-slot:item.customers_user="{ item }">
      <span v-if="item.customers_user">
        <strong>
          {{ item.customers_user.last_name }}
          {{ item.customers_user.first_name }}
        </strong>
        <br />
        <small class="text--secondary">
          {{ item.customers_user.mobile_phone_number }}
        </small>
      </span>
      <span v-else> - </span>
    </template>

    <template v-slot:item.tobe_managed_by="{ item }">
      {{ item.tobe_managed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
    </template>
    <template v-slot:item.tobe_closed_by="{ item }">
      {{ item.tobe_closed_by | momentOr('DD/MM/YYYY HH:mm', '-') }}
    </template>

    <template v-slot:actions>
      <v-btn
        v-if="canUser('tickets', 'create')"
        color="dark grey"
        dark
        tile
        depressed
      >
        Aggiungi
      </v-btn>
      <!-- ND: ABBIAMO DISABILITATO IL TASTO AGGIUNGI
        @click="openModal"
      -->
    </template>
    <template v-slot:footer>
      <v-dialog
        v-model="dialog"
        max-width="75%"
        :fullscreen="$vuetify.breakpoint.mobile"
        :retain-focus="false"
        @click:outside="modalClose"
      >
        <v-card>
          <v-card-title>Ticket</v-card-title>
          <v-card-text>
            <TicketsForm :edit="editMode" @submitted="onSubmitted" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </BaseTable>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'
import TicketsForm from '@components/tickets/TicketsForm.vue'

const { mapFields: mapTicketsFilters } = createHelpers({
  getterType: 'tickets/getFiltersFields',
  mutationType: 'tickets/SET_FILTER_FIELDS',
})

export default {
  name: 'CustomerTickets',
  components: {
    TicketsForm,
    BaseTable,
  },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: '#ID',
          align: 'start',
          value: 'external_id',
        },
        {
          text: 'Utente',
          value: 'customers_user',
        },
        {
          text: 'Attività',
          align: 'start',
          value: 'activity',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'Esito',
          value: 'resolution',
        },
        {
          text: 'In Carico',
          value: 'managed_at',
        },
        {
          text: 'Chiusura',
          value: 'closed_at',
        },
        {
          text: 'Data Appuntamento',
          value: 'date_appointment',
        },
        {
          text: 'Inserito',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Vai alla Pratica',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          to: ({ id }) => ({ name: 'tickets_detail', params: { id } }),
          onItemCondition: () => this.canUser('tickets', 'read'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    ...mapTicketsFilters(['customer_id']),
  },
  mounted() {
    this.setScopes(['activity'])
    this.customer_id = this.customer.id
  },
  beforeDestroy() {
    this.setScopes([])
    this.resetFilters()
  },
  methods: {
    ...mapMutations('tickets', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),
    openModal(item) {
      const startingPoint = item.id ? item : { customer_id: this.customer.id }
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
