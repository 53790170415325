var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{ref:"table",attrs:{"title":"Pratiche","headers":_vm.headers,"actions":_vm.actions,"elevation":0,"module":"tickets"},scopedSlots:_vm._u([{key:"item.external_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.external_id))])]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.activity.macro)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(item.activity.category)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(item.activity.subcategory)+" ")]}},{key:"item.customers_user",fn:function(ref){
var item = ref.item;
return [(item.customers_user)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(item.customers_user.last_name)+" "+_vm._s(item.customers_user.first_name)+" ")]),_c('br'),_c('small',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(item.customers_user.mobile_phone_number)+" ")])]):_c('span',[_vm._v(" - ")])]}},{key:"item.tobe_managed_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.tobe_managed_by,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"item.tobe_closed_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.tobe_closed_by,'DD/MM/YYYY HH:mm', '-'))+" ")]}},{key:"actions",fn:function(){return [(_vm.canUser('tickets', 'create'))?_c('v-btn',{attrs:{"color":"dark grey","dark":"","tile":"","depressed":""}},[_vm._v(" Aggiungi ")]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"75%","fullscreen":_vm.$vuetify.breakpoint.mobile,"retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Ticket")]),_c('v-card-text',[_c('TicketsForm',{attrs:{"edit":_vm.editMode},on:{"submitted":_vm.onSubmitted}})],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }