<template>
  <v-container fluid>
    <CustomerDetail v-if="customer.id" @refresh="refresh" />

    <v-tabs v-model="tab" class="mt-6" background-color="transparent">
      <!-- piani sottoscritti -->
      <v-tab v-if="canUserSee('tickets')" href="#tickets">
        <v-icon left>mdi-face-agent</v-icon>
        Richieste & Pratiche
      </v-tab>
      <v-tab-item v-if="canUserSee('tickets')" value="tickets">
        <CustomerTickets v-if="customer.id" />
      </v-tab-item>

      <!--
      <v-tab v-if="canUserSee('customers.attachments')" href="#attachments">
        <v-icon left>mdi-file-document-multiple-outline</v-icon>
        Documentazione
      </v-tab>
      <v-tab-item
        v-if="canUserSee('customers.attachments')"
        value="attachments"
      >
        <CustomerAttachments v-if="customer.id" />
      </v-tab-item>
      -->
    </v-tabs>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CustomerDetail from '@components/customers/CustomerDetail.vue'
import { authComputed } from '@state/helpers.js'
// ND: ABBIAMO DISABILITATO IL TAB DOCUMENTAZIONE
// import CustomerAttachments from '@components/customers/CustomerAttachments.vue'
import CustomerTickets from '@components/customers/CustomerTickets.vue'
import storeReset from '@mixins/storeReset'

export default {
  name: 'CustomerDetails',
  page: {
    title: 'Dettagli Cliente',
  },
  components: {
    CustomerTickets,
    // CustomerAttachments,
    CustomerDetail,
  },
  mixins: [storeReset],
  data: () => ({
    tabs: null,
  }),
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: (state) => state.current,
    }),
    tab: {
      set(sub) {
        this.$router.replace({ query: { ...this.$route.query, sub } })
      },
      get() {
        return this.$route.query.sub
      },
    },
  },
  async mounted() {
    await this.refresh()
  },
  beforeDestroy() {
    this.storeReset({
      customers: ['current'],
    })
  },
  methods: {
    ...mapActions('customers', {
      setCurrent: 'setCurrent',
    }),
    async refresh() {
      await this.setCurrent(this.$route.params.id)
    },
  },
}
</script>

<style>
.v-tabs-items {
  background-color: transparent !important;
}
</style>
