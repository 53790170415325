<template>
  <div>
    <v-card elevation="0" tile outlined :loading="loading">
      <v-row dense no-gutters>
        <v-col :cols="12" :lg="9" :order="2" :order-lg="1">
          <v-card-title>
            <v-chip class="mr-3" color="green" dark>
              {{ customer.code }}
            </v-chip>

            <v-chip
              v-if="customer.piva_cfisc"
              class="mr-3"
              color="primary"
              dark
            >
              {{ customer.piva_cfisc }}
            </v-chip>
            {{ customer.common_name }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col :cols="6" :md="3" :lg="3" :xl="3">
                <small class="d-block">Referente</small>
                <h3>{{ customer.last_name }} {{ customer.first_name }}</h3>
              </v-col>
              <v-col :cols="6" :md="3" :lg="3" :xl="3">
                <small class="d-block">Email</small>
                <h3>{{ customer.email | placeholder('-') }}</h3>
              </v-col>
              <v-col :cols="6" :md="3" :lg="3" :xl="3">
                <small class="d-block">Telefono</small>
                <h3>{{ customer.work_phone_number | placeholder('-') }}</h3>
              </v-col>
              <v-col :cols="6" :md="3" :lg="3" :xl="3">
                <small class="d-block">Cellulare</small>
                <h3>{{ customer.mobile_phone_number | placeholder('-') }}</h3>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col :cols="6" :md="3" :lg="3" :xl="3">
                <small class="d-block">Indirizzo</small>
                <h3>{{ customer.address }} - {{ customer.city }}</h3>
              </v-col>
              <v-col :cols="6" :md="3" :lg="3" :xl="3">
                <small class="d-block">Creato</small>
                <h3>{{ customer.created_at | moment('DD/MM/YYYY HH:mm') }}</h3>
              </v-col>
              <v-col :cols="12" :md="6" :lg="6" :xl="6">
                <small class="d-block">Note</small>
                <strong>{{ customer.notes | placeholder('-') }}</strong>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <!-- <v-card-actions v-if="canUser('customers', 'update')">
            <v-btn
              v-if="canUser('customers', 'update')"
              depressed
              text
              large
              color="primary"
              @click="dialog = true"
            >
              <v-icon left>mdi-account-edit</v-icon>
              Modifica dettagli
            </v-btn>
          </v-card-actions> -->
        </v-col>
        <v-col :cols="12" :lg="3" :order="1" :order-lg="2">
          <CustomerMap :zoom="3" />
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="75%"
      :fullscreen="$vuetify.breakpoint.mobile"
      :retain-focus="false"
      @click:outside="refresh"
    >
      <v-card>
        <v-card-title>Dettagli Cliente</v-card-title>
        <v-card-text>
          <CustomersForm
            v-if="dialog"
            title=""
            :edit="true"
            @submitted="onSubmitted"
            @resetted="refresh"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CustomersForm from '@components/customers/CustomersForm.vue'
import CustomerMap from '@components/customers/CustomerMap.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'CustomerDetail',
  components: { CustomerMap, CustomersForm },
  computed: {
    ...authComputed,
    ...mapState('customers', {
      customer: 'current',
      loading: 'loading',
    }),
  },
  data: () => ({
    dialog: false,
    /* dialogNotification: false, */
  }),
  methods: {
    onSubmitted() {
      this.dialog = false
      /* this.dialogNotification = false */
      this.refresh()
    },
    refresh() {
      this.$emit('refresh')
    },
  },
}
</script>

<style scoped></style>
