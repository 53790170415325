<template>
  <v-row :dense="dense">
    <v-col
      v-for="field in validFields"
      :key="field.key"
      :sm="field.sm"
      :md="field.md"
      :lg="field.lg"
      :xl="field.xl"
    >
      <v-row :dense="dense">
        <FormItem
          v-if="!CUSTOM_FIELS_TYPE.includes(field.type)"
          v-model="field.value"
          :name="field.name"
          :clearable="field.clearable"
          :type="field.type"
          :values="field.values"
          :single="field.single"
          :cols="field.cols"
          :rules="field.rules"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          @input="(value) => onChange(field.key)(value)"
        />

        <FormUploadExcel
          v-else-if="field.type === 'fileImporter'"
          v-model="field.value"
          :name="field.name"
          :clearable="field.clearable"
          :cols="field.cols"
          :rules="field.rules"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          @input="(value) => onChange(field.key)(value)"
        />
      </v-row>
      <v-row v-if="field.preview" dense>
        <v-col cols="12">
          <h4>{{ field.preview.title }}</h4>
        </v-col>
        <v-col v-for="group in field.preview.groups" :key="group.id">
          {{ group.title }}

          <span v-for="option in group.options" :key="option.label">
            <v-checkbox
              v-model="option.selected"
              :disabled="
                !field.value || option.disabled_on.includes(field.value)
              "
              :readonly="isReadOnly(group, field)"
              :label="option.label"
              :ripple="false"
              dense
              hide-details
              @click="emit"
            />
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import FormUploadExcel from '@components/forms/Advanced/FormUploadExcel.vue'

export default {
  name: 'TicketAdditionalFields',
  components: { FormUploadExcel, FormItem },
  props: {
    mode: {
      type: String,
      validator: (value) => ['object', 'array'].includes(value),
      default: 'object',
    },
    value: {
      type: [Object, Array],
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    res: {},
    CUSTOM_FIELS_TYPE: ['fileImporter'],
  }),
  computed: {
    validFields: function() {
      const that = this
      const validFields = this.fields.filter(function(field) {
        if (!field.condition) return true
        if (
          that.fields.some(
            (f) =>
              f.key === field.condition.key &&  field.condition.values.includes(f.value)
          )
        ) {
          return true
        } else {
          // reset fields that are not fufilling the condition
          field.value = null
          that.res[field.key] = null
          that.resetFieldOptions(field.key)
          return false
        }
      })
      this.emit()
      return validFields
    },
  },
  watch: {
    fields: {
      immediate: true,
      // set null on every key on res to maintain the the fields order
      handler(val) {
        if (!val) return

        this.fields.forEach((i) => {
          if (!this.res[i.key]) {
            this.res[i.key] = null
          }
        })

        this.emit()
      },
    },
  },
  methods: {
    onChange(key) {
      return (value) => {
        this.res[key] = value

        // reset preview options, if any
        this.resetFieldOptions(key)

        this.emit()
      }
    },
    emit() {
      this.$emit(
        'input',
        this.mode === 'object' ? this.res : this.convertToArray()
      )
    },

    resetFieldOptions(key) {
      const { value, preview } = this.fields.find((i) => i.key === key)

      if (!preview) {
        return
      }

      preview.groups.forEach((group) => {
        group.options.forEach(
          (option) => (option.selected = option.checked_on.includes(value))
        )
      })
    },

    isReadOnly(group, field) {
      if (!group || !field) return false

      const currentValue = field.values.find((v) => v.value === field.value)

      // options are read only when group is marked readonly
      // and current selected value has no unlockOptions
      return group.readonly && !(currentValue && currentValue.unlockOptions)
    },
    convertToArray() {
      return Object.keys(this.res).map((key) => {
        const { name, values, preview } = this.fields.find((i) => i.key === key)
        const valueRaw = this.res[key]

        const value = values
          ? values.find((v) => v.value === valueRaw)
          : valueRaw

        let opts = null

        if (preview) {
          const { groups } = preview

          // pickin' labels of selected options per group
          opts = groups.map((group) => {
            return {
              text: group.title,
              value: group.options
                .filter((o) => o.selected)
                .map((o) => o.label),
            }
          })
        }

        return { key, name, rawValue: valueRaw, value: value?.text, opts }
      })
    },
  },
}
</script>

<style scoped></style>