<template>
  <Form :loading="loading" @submit="onSubmit" @reset="onReset">
    <v-card-subtitle class="text-center overline">
      1. Dettagli Cliente
    </v-card-subtitle>

    <v-row dense>
      <FormCustomers
        v-if="!hideCustomer"
        v-model="customer_id"
        :disabled="!!currentCustomerId"
        :customer_id="customer_id"
        name="Seleziona Cliente*"
        hint="Inserisci ragione sociale, codice o P.IVA"
        persistent-hint
        :rules="{ required: !file }"
        :sm="12"
        :md="4"
        :lg="3"
        :xl="3"
      />

      <CustomersSubscriptionsSelect
        v-model="plans_subscription_id"
        :disabled="!!currentCustomerId"
        :customerid="customer_id"
        :customer_user_id="customer_user_id"
        name="Sottoscrizione"
        auto-select
        :rules="{ required: !file }"
        :sm="12"
        :md="3"
        :lg="2"
        :xl="2"
      />

      <CustomersLocationsSelect
        v-model="customer_location_id"
        :customerid="customer_id"
        name="Sede"
        clearable
        auto-select
        :md="4"
        :lg="3"
        :xl="3"
      />
    </v-row>
    <v-row dense>
      <v-col :cols="12" :md="6" :lg="5" :xl="4">
        <v-row dense>
          <FormCustomersUsers
            ref="customersUsersSelect"
            v-model="customer_user_id"
            :customer_id="customer_id"
            :customer_user_id="customer_user_id"
            :disabled="!customer_id"
            name="Utente"
            clearable
            :cols="
              customer_id &&
              !customer_user_id &&
              canUser('customers.users', 'create')
                ? 9
                : 12
            "
            :sm="
              customer_id &&
              !customer_user_id &&
              canUser('customers.users', 'create')
                ? 9
                : 12
            "
            :md="
              customer_id &&
              !customer_user_id &&
              canUser('customers.users', 'create')
                ? 9
                : 12
            "
            :lg="
              customer_id &&
              !customer_user_id &&
              canUser('customers.users', 'create')
                ? 9
                : 12
            "
            :xl="
              customer_id &&
              !customer_user_id &&
              canUser('customers.users', 'create')
                ? 9
                : 12
            "
          />
          <v-col
            v-if="canUser('customers.users', 'create')"
            v-show="customer_id && !customer_user_id"
            :cols="3"
          >
            <CustomerUsersBtn
              :customer_id="customer_id"
              @created="onCustomerUserCreation"
            />
          </v-col>
        </v-row>
      </v-col>

      <FormCustomersAsset
        v-if="activity && activity.asset_required"
        v-model="asset_id"
        :customer_id="customer_id"
        :customer_user_id="userAssetLink"
        :disabled="!customer_id"
        clearable
        :md="4"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="customer_callback_num"
        name="Num. ricontatto"
        hint="Inserisci il numero al quale si desidera essere ricontattati."
        :rules="{ required: !!currentCustomerId }"
        :cols="8"
        :sm="8"
        :md="6"
        :lg="4"
        :xl="3"
      />
    </v-row>

    <v-card-subtitle class="text-center overline">
      2. Dettagli Richiesta
    </v-card-subtitle>

    <v-row dense>
      <FormItem
        v-model="macro"
        name="Commessa"
        :disabled="isMacroLocked"
        :values="
          macrosByContext({
            customerId: customer_id,
            // category,
            // subcategory,
          })
        "
        type="autocomplete"
        single
        :multiple="false"
        :rules="{ required: true }"
        :clearable="!isMacroLocked"
        :cols="12"
        :sm="12"
        :md="3"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model="category"
        :disabled="!macro || isCategoriesLocked"
        :values="
          categoriesByContext({
            customerId: customer_id,
            macro,
            // subcategory,
          })
        "
        :clearable="!isCategoriesLocked"
        type="autocomplete"
        single
        name="Categoria"
        :rules="{ required: true }"
        :cols="12"
        :sm="12"
        :md="4"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="subcategory"
        :values="
          subcategoriesByContext({
            customerId: customer_id,
            macro,
            category,
          })
        "
        :disabled="!(macro && category) || isSubcategoriesLocked"
        type="autocomplete"
        single
        name="Sottocategoria"
        :rules="{ required: true }"
        :cols="12"
        :sm="12"
        :md="4"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-if="activity && activity.external_id_required"
        v-model="external_id"
        type="text"
        name="Identificativo Esigenza"
        placeholder="Identificativo Esigenza"
        :rules="{ required: true }"
        :cols="12"
        :sm="12"
        :md="3"
        :lg="2"
        :xl="2"
      />

      <FormChannels
        v-if="!currentCustomerId"
        v-model="channel"
        name="Canale di ingaggio"
        :cols="12"
        :sm="12"
        :md="3"
        :lg="2"
        :xl="2"
      />

      <FormDatetimePicker
        v-if="receivedAtRequired"
        v-model="received_at"
        name="Data/ora Ingaggio"
        date-format="dd/MM/yyyy"
        :rules="{ required: !skip_appointment && !file }"
        :sm="12"
        :md="3"
        :lg="2"
        :xl="2"
      />
    </v-row>
    <v-alert
      v-if="activity && activity.description"
      type="info"
      outlined
      dismissible
    >
      <strong>{{ subcategory }}:</strong> {{ activity.description }}
    </v-alert>

    <TicketAdditionalFields
      v-model="additional_fields"
      mode="array"
      :fields="customField"
    />

    <v-row dense>
      <FormItem
        v-model="customer_notes"
        name="Inserisci Note"
        type="textarea"
        :rows="5"
        :cols="12"
        :sm="12"
        :md="8"
        :lg="6"
        :xl="6"
      />

      <v-col :cols="12" :sm="12" :md="8" :lg="6" :xl="6">
        <v-row dense>
          <v-col>
            <v-file-input
              v-model="files"
              label="Aggiungi allegati"
              rule="size:10000"
              show-size
              filled
              clearable
              multiple
              hint="E' possibile caricare immagini, documenti ed archivi con una dimensione massima di 10MB."
              persistent-hint
            ></v-file-input>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-subtitle
      v-if="activity && activity.appointment_required"
      class="text-center overline"
    >
      3. Fissa un appuntamento
    </v-card-subtitle>

    <v-row v-if="activity && activity.appointment_required" dense>
      <v-col :cols="12" :sm="12" :md="4" :lg="4" :xl="4">
        <v-row>
          <FormItem
            v-show="agendas.length > 1"
            v-model="agenda_id"
            type="select"
            name="Seleziona Agenda"
            hint="Sono state trovate più agende disponibili. Seleziona lo slot  di interesse."
            persistent-hint
            :values="agendas"
            :cols="12"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          />
          <FormItem
            v-model="formatted_date_appointment"
            name="Inizio Appuntamento"
            readonly
            :rules="{ required: !skip_appointment && !file }"
            :disabled="skip_appointment"
            :cols="6"
            :sm="6"
            :md="6"
            :lg="6"
            :xl="6"
          />
          <FormItem
            v-model="formatted_date_appointment_end"
            name="Fine Appuntamento"
            readonly
            :rules="{ required: !skip_appointment && !file }"
            :disabled="skip_appointment"
            :cols="6"
            :sm="6"
            :md="6"
            :lg="6"
            :xl="6"
          />
          <FormItem
            v-if="false"
            v-model="skip_appointment"
            type="switch"
            name="Procedi senza appuntamento"
            :cols="12"
            dense
          />
        </v-row>
      </v-col>
      <v-col :cols="12" :sm="12" :md="8" :lg="8" xl="8">
        <SlotsCalendar :agenda-id="agenda_id" @slot-click="onSlotClick" />
      </v-col>
    </v-row>

    <v-divider v-if="!edit && canUser('tickets', 'import')" class="my-6" />
    <v-row v-if="!edit && canUser('tickets', 'import')">
      <v-col>
        <p>
          E' possibile caricare massivamente più cliente procedendo al
          caricamento multiplo.
          <br />
          <a href="/static/templates/customers_import_template.xlsx">Scarica</a>
          il template personalizzato e caricalo dopo aver riempito i campi
          necessari.
        </p>
      </v-col>
      <v-col cols="5">
        <v-file-input
          v-model="file"
          label="Import massivo XLS"
          accept="application/vnd.ms-excel || application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          rule="size:100"
          show-size
          filled
          clearable
        ></v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
          v-if="!plans_subscription_id && customer_user_id"
          type="warning"
        >
          Per procedere all'invio, l'utenza deve aver associata una
          sottoscrizione.
        </v-alert>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormChannels from '@components/forms/FormChannels.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'
import { authComputed } from '@state/helpers.js'
import FormDatetimePicker from '@components/forms/FormDatetimePicker.vue'
import TicketAdditionalFields from '@components/tickets/inc/TicketAdditionalFields.vue'

const { mapFields } = createHelpers({
  getterType: 'tickets/getCurrent',
  mutationType: 'tickets/SET_CURRENT_FIELDS',
})

const { mapFields: mapFieldsCustomerAttachments } = createHelpers({
  getterType: 'customersAttachments/getCurrent',
  mutationType: 'customersAttachments/SET_CURRENT_FIELDS',
})

export default {
  name: 'TicketsForm',
  components: {
    FormDatetimePicker,
    Form,
    FormChannels,
    FormCustomers,
    TicketAdditionalFields,
    FormItem,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
    hideCustomer: {
      type: Boolean,
      default: false,
    },
    hideUser: {
      type: Boolean,
      default: false,
    },
    hideAsset: {
      type: Boolean,
      default: false,
    },
    doRedirect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'channel',
      'customer_notes',
      'customer_callback_num',
      'operator_notes',
      'managed_at',
      'assigned_at',
      'solicited_at',
      'solicit_number',
      'solicited_managed_at',
      'closed_at',
      'revoked_at',
      'prioritary',
      'extra',
      'activity_id',
      'status',
      'resolution',
      'customer_id',
      'customer_user_id',
      'customer_location_id',
      'asset_id',
      'slot_id',
      'plans_subscription_id',
      'external_id',
      'activity.macro',
      'activity.category',
      'activity.subcategory',
      'activity.asset_required',
      'activity.external_id_required',
      'date_appointment',
      'date_appointment_end',
      'file',
      'files',
      'received_at',
      'additional_fields',
    ]),
    ...mapFieldsCustomerAttachments({
      name_ca: 'name',
      filename_ca: 'filename',
      description_ca: 'description',
      type_ca: 'type',
      file_ca: 'file',
      extra_ca: 'extra',
      customer_id_ca: 'customer_id',
      ticket_id_ca: 'ticket_id',
      visibile_ca: 'visible',
    }),
    ...mapState('tickets', {
      id: (state) => state.current.id,
      loading: (state) => state.loading,
      ticket: (state) => state.current,
    }),
    ...mapGetters('tickets', {
      formatted_date_appointment: 'getFormattedDateAppointment',
      formatted_date_appointment_end: 'getFormattedDateAppointmentEnd',
    }),
    ...mapGetters('ticketsActivities', {
      getActivitiesByContext: 'activitiesByContext',
      getActivityByTriplet: 'activityByTriplet',
      macrosByContext: 'macrosByContext',
      categoriesByContext: 'categoriesByContext',
      subcategoriesByContext: 'subcategoriesByContext',
    }),
    ...mapState('agendas', {
      agendas: (state) => state.list,
    }),
    agendaKeyWatcher() {
      return `${this.customer_id}|${this.customer_location_id}|${this.macro}|${this.category}|${this.subcategory}`
    },
    receivedAtRequired() {
      return ['mail', 'telefono'].includes(this.channel)
    },
    userAssetLink() {
      return this.canUser('tickets', 'asset_user_link')
        ? this.customer_user_id
        : null
    },
    isMacroLocked() {
      return (
        this.macrosByContext({
          customerId: this.customer_id,
          // category,
          // subcategory,
        }).length === 1
      )
    },
    isCategoriesLocked() {
      return (
        this.categoriesByContext({
          customerId: this.customer_id,
          macro: this.macro,
        }).length === 1
      )
    },
    isSubcategoriesLocked() {
      return (
        this.subcategoriesByContext({
          customerId: this.customer_id,
          macro: this.macro,
          category: this.category,
        }).length === 1
      )
    },
  },
  data: () => ({
    activity: null,
    agenda_id: null,
    activities: [],
    skip_appointment: false,
    customField: [],
    output: {},
  }),
  watch: {
    /* When the customer changes (not from null) reset all fields */
    customer_id: {
      handler(val, oldVal) {
        if (val && oldVal && !this.currentCustomerId) {
          this.reset()
          this.customer_id = val
          this.setSingleActivity()
        }
      },
    },
    asset_id: {
      handler(val) {
        const asset = this.$store.state.customersAssets.list.find(
          (asset) => asset.id === val
        )
        if (asset) {
          if (asset.customer_user_id) {
            this.customer_user_id = asset.customer_user_id
          }
          if (asset.plans_subscription_id) {
            this.plans_subscription_id = asset.plans_subscription_id
          }
        }
        this.asset_id = val
      },
    },
    macro: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.activity = null
          this.category = null
          this.subcategory = null
          this.macro = null
          return
        }

        const values = this.categoriesByContext({
          customerId: this.customer_id,
          macro: this.macro,
        })

        if (values.length === 1) {
          this.category = values[0]
        }
      },
    },
    category: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.activity = null
          this.category = null
          this.subcategory = null
          return
        }

        const values = this.subcategoriesByContext({
          customerId: this.customer_id,
          macro: this.macro,
          category: this.category,
        })

        if (values.length === 1) {
          this.subcategory = values[0]
        }
      },
    },
    subcategory: {
      immediate: true,
      handler: function(val) {
        if (!val) {
          this.activity = null
          this.subcategory = null
          return
        }

        this.activity = this.getActivityByTriplet(
          this.macro,
          this.category,
          this.subcategory
        )

        this.customField = this.activity?.additional_fields || []
      },
    },
    agendaKeyWatcher: {
      immediate: true,
      handler: 'getActiveAgendas',
    },
    receivedAtRequired(val) {
      if (!val) this.received_at = null
      else this.received_at = new Date()
    },
  },
  async mounted() {
    if (this.$route.params.key) {
      await this.getDropdownList({
        filters: { grouping_key: this.$route.params.key },
      })
      this.activities = this.getActivitiesByContext()
      this.setSingleActivity()
    } else await this.getDropdownList({})
    this.$nextTick(() => {
      this.customer_id = this.currentCustomerId
      this.customer_user_id = this.currentCustomerUserId

      // utenza cliente può solo creare ticket di canale web,
      // utenza con profilo elevato può evitare di impostare un appuntamento
      if (this.currentCustomerId) this.channel = 'web'
      else this.skip_appointment = true

      const macros = this.macrosByContext({ customerId: this.customer_id })
      if (macros.length) this.macro = macros[0]
    })
  },
  beforeDestroy() {
    this.reset()
    this.resetAgendas()
    this.resetActivities()
  },
  methods: {
    ...mapMutations('tickets', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapMutations('agendasSlots', {
      highlightSlot: 'HIGHLIGHTS_CURRENT_LIST',
    }),
    ...mapActions('tickets', ['create', 'update', 'createBulk']),

    ...mapActions('ticketsActivities', ['getDropdownList']),

    ...mapActions('agendas', {
      fetchAgendas: 'getDropdownList',
    }),
    ...mapActions('customersAttachments', {
      createAttachment: 'create',
    }),
    ...mapMutations('agendas', {
      resetAgendas: 'RESET_LIST',
    }),

    ...mapMutations('ticketsActivities', {
      resetActivities: 'RESET_LIST',
    }),

    async getActiveAgendas() {
      await this.fetchAgendas({
        filters: {
          macro: this.macro,
          category: this.category,
          customer_ids: this.customer_id,
          customers_location_ids: this.customer_location_id,
        },
      })

      if (this.agendas.length && !this.agenda_id)
        this.agenda_id = this.agendas[0].value
    },

    onSlotClick({ nativeEvent, event }) {
      if (event.locked) {
        return
      }

      if (this.slot_id === event.id) {
        this.highlightSlot(null)
        this.slot_id = null
        this.date_appointment = null
        this.date_appointment_end = null
      } else {
        this.highlightSlot(event.id)
        this.slot_id = event.id
        this.date_appointment = event.start
        this.date_appointment_end = event.end
      }
    },

    async onSubmit() {
      this.$emit('submit')
      try {
        if (this.file) {
          await this.createBulk()
          this.$emit('submitted')
          this.reset()
          return
        }
        let data
        if (this.$route.params.key && this.activity.external_id_required) {
          this.external_id = `${this.external_id}-${this.category[0]}`;
        }
        if (!this.edit) {
          data = await this.create()
          this.customer_id_ca = this.customer_id
          this.ticket_id_ca = data.id
          this.visibile_ca = true

          await Promise.all(
            this.files.map(async (file, index) => {
              this.file_ca = file
              this.name_ca = `Allegato ${index + 1}`
              await this.createAttachment()
            })
          )
        } else {
          data = await this.update()
        }

        this.$emit('submitted', data.id)
      } catch (err) {
        console.debug(err)
      }
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
    async onCustomerUserCreation(id) {
      this.customer_user_id = id
    },
    setSingleActivity() {
      if (this.activities.length !== 1) return

      this.activity = this.activities[0]
      this.customField = this.activity.additional_fields
      this.macro = this.activity.macro
      this.category = this.activity.category
      this.subcategory = this.activity.subcategory
    },
  },
}
</script>

<style scoped></style>
